import { useContext, useEffect, useState } from "react"
import { useFormik } from "formik"
import { useIntl } from "react-intl"
import * as Yup from "yup"
import { TbUpload } from "react-icons/tb"
import { FaCircleCheck } from "react-icons/fa6"
import { debounce } from "lodash"
import { toast } from "react-toastify"

import { AuthenticationContext } from "@/context/AuthenticateContext"
import { Button, ErrorMessage, Required, SelectAsync, SuperInput } from "@/@core"
import { PlacePrediction } from "../Zones/ZoneType"
import { useUsersAdmins } from "../UsersAdmins/hooks/useUsersAdmins"
import { CONFIG_MAIN_TOAST } from "@/constants"
import laravelErrors from "@/utils/laravel.errors"
import { getPlaceDetails } from "@/utils/apiGoogle"

interface Props {
  isOpen: boolean
  onClose: () => void
  onUpdateData(company: Company): void
}

export const CompanyForm = ({ isOpen, onClose, onUpdateData }: Props) => {
  const AutocompleteService = window.google ? new window.google.maps.places.AutocompleteService() : undefined
  const [locations, setLocations] = useState<PlacePrediction[]>([])
  const { updateCompanyInformation } = useUsersAdmins()
  const { formatMessage: $t } = useIntl()
  const { Auth: authenticate } = useContext(AuthenticationContext)!
  const [previewImage, setPreviewImage] = useState<string>(authenticate.company?.full_company_logo_path || "")

  const formik = useFormik({
    initialValues: {
      name: authenticate.company?.name || "",
      email: authenticate.company?.email || "",
      address: authenticate.company?.address || "",
      address2: authenticate.company?.address2 || "",
      city: authenticate.company?.city || "",
      zip_code: authenticate.company?.zip_code || "",
      country: authenticate.company?.country || "",
      state: authenticate.company?.state || "",
      logo: null as File | null,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required($t({ id: "Required" }))
        .min(3),
      email: Yup.string()
        .email($t({ id: "invalid email" }))
        .required($t({ id: "Required" })),
      address: Yup.string()
        .required($t({ id: "Required" }))
        .min(3),
      address2: Yup.string().min(3),
      city: Yup.string()
        .required($t({ id: "Required" }))
        .min(2),
      zip_code: Yup.string()
        .required($t({ id: "Required" }))
        .min(5),
      state: Yup.string()
        .required($t({ id: "Required" }))
        .min(2),
    }),
    onSubmit: async (values, helpers) => {
      try {
        const formData = new FormData()
        Object.entries(values).forEach(([key, value]) => {
          if (value !== null) {
            formData.append(key, value)
          }
        })
        if (values.country) formData.delete("country")
        const response = await updateCompanyInformation(formData)
        const { data, status, msg } = response.data
        if (status) {
          toast.success($t({ id: "It has been updated successfully" }), CONFIG_MAIN_TOAST)
          onUpdateData(data)
          onClose()
        } else {
          if (typeof msg === "object") {
            helpers.setErrors(laravelErrors.toObject(msg))
          }
        }
      } catch (error) {
        console.error(error)
      }
    },
    validateOnChange: false,
  })

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files?.[0]
    if (file) {
      formik.setFieldValue("logo", file)
      const reader = new FileReader()
      reader.onloadend = () => {
        setPreviewImage(reader.result as string)
      }
      reader.readAsDataURL(file)
    }
  }

  const getDetails = async (value: PlacePrediction) => {
    try {
      const details = await getPlaceDetails(value)
      if (details) {
        formik.setFieldValue("city", String(details.city).toLocaleUpperCase())
        formik.setFieldValue("state", String(details.state).toLocaleUpperCase())
        if (!!details.zip_code) formik.setFieldValue("zip_code", String(details.zip_code).toLocaleUpperCase())
        formik.setFieldValue("address", details.address_1)
      }
    } catch (error) {}
  }

  const searchAddressDebounce = debounce(async () => {
    try {
      if (formik.values.address) {
        const { predictions } = await AutocompleteService.getPlacePredictions({
          input: formik.values.address || "",
          componentRestrictions: { country: ["us"] },
          fields: ["formatted_address", "geometry"],
          language: "en",
        })
        if (predictions) {
          setLocations(predictions)
        }
      }
    } catch (error) {}
  }, 400)

  const cleanAddressDetails = () => {
    formik.setFieldValue("city", "")
    formik.setFieldValue("state", "")
    formik.setFieldValue("zip_code", "")
  }

  const clearSearch = () => {
    setLocations([])
    formik.setFieldValue("address", "")
    cleanAddressDetails()
  }

  useEffect(() => {
    if (AutocompleteService) {
      searchAddressDebounce()
      return () => searchAddressDebounce.cancel()
    }
  }, [formik.values.address])

  if (!isOpen) return null

  return (
    <form onSubmit={formik.handleSubmit} className="space-y-3">
      <div className="flex justify-center mb-4">
        <div className="relative">
          <div className="w-32 h-32 border-2 border-dashed rounded-lg flex items-center justify-center overflow-hidden">
            {previewImage ? (
              <img src={previewImage} alt="Company logo" className="w-full h-full object-contain" />
            ) : (
              <TbUpload className="text-4xl text-gray-400" />
            )}
          </div>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            className="absolute inset-0 opacity-0 cursor-pointer"
            title={$t({ id: "Upload logo" })}
          />
          <ErrorMessage error={formik.errors.logo} />
        </div>
      </div>

      <div className="grid grid-cols-12 gap-1 gap-y-3">
        <div className="col-span-6">
          <SuperInput
            id="panel_company_name"
            name="name"
            label={$t({ id: "Name" })}
            placeholder={$t({ id: "Company name" })}
            onChange={formik.handleChange}
            value={formik.values.name}
            error={formik.errors.name}
            maxLength={255}
            showRequired
          />
        </div>
        <div className="col-span-6">
          <SuperInput
            id="panel_company_email"
            name="email"
            label={$t({ id: "Email" })}
            onChange={formik.handleChange}
            value={formik.values.email}
            error={formik.errors.email}
            maxLength={255}
            showRequired
          />
        </div>
        <div className="col-span-12">
          <label htmlFor="panel_company_address" className="text-xs">
            {$t({ id: "Address" })} <Required />
          </label>
          <SelectAsync
            id="panel_company_address"
            name="address"
            options={locations}
            value={formik.values.address}
            onChange={(ev) => {
              formik.handleChange(ev)
              cleanAddressDetails()
            }}
            onSelected={getDetails}
            onDeselected={clearSearch}
          >
            {(prediction) => (
              <SelectAsync.Option key={crypto.randomUUID().toString()} data={prediction}>
                {prediction.description}
              </SelectAsync.Option>
            )}
          </SelectAsync>
          <ErrorMessage error={formik.errors.address} />
        </div>
        <div className="col-span-12">
          <SuperInput
            id="panel_company_address2_form"
            name="address2"
            autoComplete="off"
            label={$t({ id: "Address 2" })}
            onChange={formik.handleChange}
            value={formik.values.address2}
            error={formik.errors.address2}
            maxLength={255}
          />
        </div>
        <div className="col-span-6">
          <SuperInput
            id="panel_company_city_form"
            name="city"
            label={$t({ id: "City" })}
            onChange={formik.handleChange}
            value={formik.values.city}
            error={formik.errors.city}
            maxLength={100}
            showRequired
          />
        </div>
        <div className="col-span-6">
          <SuperInput
            id="panel_company_state_form"
            name="state"
            label={$t({ id: "State" })}
            onChange={formik.handleChange}
            value={formik.values.state}
            error={formik.errors.state}
            maxLength={50}
            showRequired
          />
        </div>
        <div className="col-span-6">
          <SuperInput
            id="panel_company_zip_code_form"
            name="zip_code"
            label={$t({ id: "Zip Code" })}
            onChange={formik.handleChange}
            value={formik.values.zip_code}
            error={formik.errors.zip_code}
            maxLength={20}
            showRequired
          />
        </div>
        <div className="col-span-6">
          <SuperInput label={$t({ id: "Country" })} defaultValue={String(formik.values.country).toLocaleUpperCase()} readOnly />
        </div>
      </div>

      <div className="flex justify-end gap-2">
        <Button type="button" onClick={onClose} color="light" disabled={formik.isSubmitting}>
          {$t({ id: "Cancel" })}
        </Button>
        <Button type="submit" disabled={formik.isSubmitting}>
          <FaCircleCheck size={12} /> {$t({ id: "Save" })}
        </Button>
      </div>
    </form>
  )
}
