import { useState } from "react"
import { AxiosResponse } from "axios"
import { debounce } from "lodash"
import type {
  ResponseCarriers,
  Carrier,
  Form,
  ResponseCarrier,
  IT4CarrierResponseLogin,
  IT4CarrierResponseRegister,
  IT4CarrierResponseAssignDriver,
  IT4CarrierResponseUnassign,
} from "../CarrierType"
import axios from "@axios"

export { default as InitialState } from "../CarrierInitial.state"

export const useCarriers = (filters: TableFilters = {}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [carriers, setCarriers] = useState<Carrier[]>([])
  const [pagination, setPagination] = useState<Pagination>({
    count: 0,
    current_page: 1,
    per_page: 1,
    total: 1,
    total_pages: 1,
  })

  const fetchAll = async (params: TableFilters = filters) => {
    try {
      setLoading(true)
      const { data: res } = await axios.get<ResponseCarriers>(`carriers`, { params })
      const { status, data, pagination } = res
      if (status) {
        setPagination(pagination)
        setCarriers(data)
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const debounceFetchAll = debounce(fetchAll, 350)

  const store = async (payload: Form): Promise<AxiosResponse<ResponseCarrier>> => {
    return await axios.post<ResponseCarrier>(`carriers`, payload)
  }

  const update = async (carrierId: Form["id"], payload: Omit<Form, "id">): Promise<AxiosResponse<ResponseCarrier>> => {
    return await axios.put<ResponseCarrier>(`carriers/${carrierId}`, payload)
  }

  const destroy = async (carrierId: Carrier["id"]) => {
    try {
      setLoading(true)
      const { data: res } = await axios.delete<ResponseDelete>(`carriers/${carrierId}`)
      const { status, msg } = res
      if (!status) throw new Error(msg)
      return null
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }

  const checkRetrievedT4carrierToken = async (): Promise<AxiosResponse<{ status: boolean }>> => axios.get(`/check-retrieved-t4carrier-token`)

  const loginT4carrier = async (payload: any): Promise<AxiosResponse<IT4CarrierResponseLogin>> => axios.post(`/log-into-t4carrier`, payload)

  const registerT4carrier = async (payload: any): Promise<AxiosResponse<IT4CarrierResponseRegister>> => axios.post(`/register-into-t4carrier`, payload)

  const assignDriverToCarrierT4carrier = async (payload: any): Promise<AxiosResponse<IT4CarrierResponseAssignDriver>> =>
    axios.post(`/assign-driver-to-carrier-freight`, payload)

  const updateAssignedDriverToCarrierT4carrier = async (recordId: ModelID, payload: any): Promise<AxiosResponse<any>> =>
    axios.post(`/update-driver-to-carrier-freight/${recordId}`, payload)

  const unassignedDriverToCarrierT4carrier = async (recordId: ModelID): Promise<AxiosResponse<IT4CarrierResponseUnassign>> =>
    axios.delete(`/remove-driver-to-carrier-freight/${recordId}`)

  return {
    debounceFetchAll,
    setCarriers,
    carriers,
    setLoading,
    loading,
    fetchAll,
    store,
    destroy,
    update,
    pagination,
    // 👉 Interact with T4CARRIER
    updateAssignedDriverToCarrierT4carrier,
    unassignedDriverToCarrierT4carrier,
    assignDriverToCarrierT4carrier,
    checkRetrievedT4carrierToken,
    loginT4carrier,
    registerT4carrier,
  }
}
