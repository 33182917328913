import clsx from "clsx"
import React, { SetStateAction, useEffect, useRef, Dispatch, useState } from "react"
import { TbX } from "react-icons/tb"

interface Props {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  children?: React.ReactNode | ((callback: VoidFunction) => React.ReactNode)
  title: string | React.ReactNode
  dir?: "end" | "start"
  size?: "sm" | "lg" | "base"
  onClose?: () => void
}

export const Panel = (props: Props): JSX.Element | null => {
  const { children, title, open, onClose, setOpen, dir = "end", size = "base" } = props
  const $container = useRef<HTMLDivElement>(null)
  const panelContainerRef = useRef<HTMLDivElement>(null)
  const [active, setActive] = useState<boolean>(false)

  const emitOnClose = () => {
    setOpen(false)
    onClose && onClose()
  }

  useEffect(() => {
    if (open) setActive(true)
  }, [open])

  useEffect(() => {
    if (!active) setTimeout(emitOnClose, 150)
  }, [active])

  const newSize = {
    sm: "sm:w-[400px]",
    base: "sm:w-[450px]",
    lg: "sm:w-[500px]",
  }[size]

  const handleCloseClick = () => {
    setActive(false)
  }

  if (!open) return null

  return (
    <div
      ref={$container}
      className={clsx(
        "fixed h-screen w-screen top-0 right-0 z-[1050] flex transition-colors",
        dir === "end" ? "justify-end" : "justify-start",
        !active ? "pointer-events-none bg-black/0" : "bg-black/40 dark:bg-slate-950/70",
      )}
      onClick={(ev) => {
        ev.stopPropagation()
        ev.preventDefault()
        setActive(false)
      }}
    >
      <div
        ref={panelContainerRef}
        className={clsx(
          "panel w-full bg-white dark:bg-slate-900 h-screen relative transition",
          newSize,
          dir === "end" ? "translate-x-full" : "-translate-x-full",
          active && "!translate-x-0",
        )}
        onClick={(ev) => {
          ev.stopPropagation()
        }}
      >
        <div className="flex justify-between items-center border-b dark:border-slate-700 h-14">
          <h1 className="ps-3 text-slate-600 dark:text-slate-300">{title}</h1>
          <button type="button" className="p-3 transition-colors text-gray-400 hover:text-gray-600" onClick={handleCloseClick}>
            <TbX className="text-xl" />
          </button>
        </div>
        <div className="p-3">{typeof children === "function" ? <div>{children(handleCloseClick)}</div> : <div>{children}</div>}</div>
      </div>
    </div>
  )
}
