import { useState } from "react"
import { AxiosResponse } from "axios"
import { debounce } from "lodash"

import type { ResponseUsers, Form, ResponseRegisterUser, ResponseUser, ResponseCompanyInformation } from "@/pages/Users/UserTypes"
import axios from "@axios"

export const useUsersAdmins = (filters: TableFilters = {}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [usersAdmins, setUsersAdmins] = useState<User[]>([])
  const [pagination, setPagination] = useState<Partial<Pagination>>({})

  const fetchAll = async (params: TableFilters = filters) => {
    try {
      setLoading(true)
      const { data: res } = await axios.get<ResponseUsers>(`users_admins`, { params })
      const { status, data, pagination } = res
      if (status) {
        setPagination(pagination)
        setUsersAdmins(data)
        // setUsersAdmins(data.filter(c => c.id !== 1))
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const debounceFetchAll = debounce(fetchAll, 350)

  const store = async (payload: Form): Promise<AxiosResponse<ResponseUser>> => {
    return await axios.post<ResponseUser>(`users_admins`, payload)
  }

  const update = async (userId: number, payload: Form): Promise<AxiosResponse<ResponseUser>> => {
    return await axios.put<ResponseUser>(`users_admins/${userId}`, payload)
  }

  const destroy = async (userId: number) => {
    try {
      setLoading(true)
      const { data: res } = await axios.delete<ResponseRegisterUser>(`users_admins/${userId}`)
      const { status, msg } = res
      if (!status) {
        // @ts-ignore
        throw new Error(msg)
      }
    } catch (error) {
      // @ts-ignore
      throw new Error(error)
    } finally {
      setLoading(false)
    }
  }

  const updateCompanyInformation = async (payload: any): Promise<AxiosResponse<ResponseCompanyInformation>> => {
    return await axios.post<ResponseCompanyInformation>(`update-company-info`, payload)
  }

  return {
    debounceFetchAll,
    setUsersAdmins,
    usersAdmins,
    setLoading,
    loading,
    fetchAll,
    store,
    destroy,
    update,
    pagination,
    updateCompanyInformation,
  }
}
