import React, { useEffect, useState } from "react"
import { IntlProvider } from "react-intl"
import { createContext } from "react"
import Cookies from "js-cookie"

import LangEN from "@/lang/en.json"
import LangES from "@/lang/es.json"
import { LOCALE } from "@/constants"
import { LocalStorage } from "@/utils/localStorage"
import { renderVersion } from "@/utils"

export type TLocale = "EN" | "ES"

interface ITranslateContext {
  setLocale: React.Dispatch<React.SetStateAction<TLocale>>
  isDark: boolean
  toggleMode(): void
  changeLocale(lang: TLocale): void
  locale: TLocale
}

export const TranslateContext = createContext<ITranslateContext>({} as ITranslateContext)

const TranslateContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [locale, setLocale] = useState<TLocale>((Cookies.get(LOCALE) as TLocale) ?? "EN")
  const [isDark, setIsDark] = useState<boolean>(() => {
    const isDark = LocalStorage.getItem("dark")
    if (isDark) {
      const $html = document.querySelector("html")!
      $html.classList.add("dark")
    }
    return !!isDark
  })

  const toggleMode = () => {
    const $html = document.querySelector("html")!
    if ($html.classList.contains("dark")) {
      $html.classList.remove("dark")
      setIsDark(false)
      LocalStorage.removeItem("dark")
    } else {
      $html.classList.add("dark")
      LocalStorage.setItem("dark", "true")
      setIsDark(true)
    }
  }

  useEffect(() => {
    renderVersion()
  }, [])

  const messages = React.useMemo<Record<string, string>>(() => {
    switch (locale) {
      case "ES":
        return LangES as Record<string, string>
      default:
        return LangEN as Record<string, string>
    }
  }, [locale])

  const changeLocale = (newLocale: TLocale = "EN") => {
    if (newLocale === locale) return
    Cookies.set(LOCALE, newLocale)
    setLocale(newLocale)
  }

  const values = {
    changeLocale,
    setLocale,
    locale,
    isDark,
    toggleMode,
  }

  return (
    <TranslateContext.Provider value={values}>
      <IntlProvider locale={locale} messages={messages}>
        {children}
      </IntlProvider>
    </TranslateContext.Provider>
  )
}

export default TranslateContextProvider
