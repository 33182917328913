import { format } from "date-fns"
import { COLUMNS_DETAILS, COLUMNS_LOADS } from "./Load.columns"
import { LoadFilters } from "./LoadType"

const date = new Date()

const form = {
  current_zone: "",
  bill_to: "1",
  customer_id: "",
  customer_name: "",
  customer_address_1: "",
  customer_address_2: "",
  customer_city: "",
  customer_state: "",
  customer_zip_code: "",
  origin_id: "",
  origin_name: "",
  origin_address_1: "",
  origin_address_2: "",
  origin_city: "",
  origin_state: "",
  origin_zip_code: "",
  destination_id: "",
  destination_name: "",
  destination_address_1: "",
  destination_address_2: "",
  destination_city: "",
  destination_state: "",
  destination_zip_code: "",
  date_pickup_start: format(date, "yyyy-MM-dd'T'HH:mm:ss"),
  date_pickup_end: format(date, "yyyy-MM-dd'T'HH:mm:ss"),
  date_delivery_start: format(date, "yyyy-MM-dd'T'HH:mm:ss"),
  date_delivery_end: format(date, "yyyy-MM-dd'T'HH:mm:ss"),
  company_id: "",
  temp: "",
  description: "",
  charges: "",
  x_charges: "",
  c_charges: "",
  margin: "",
  status_id: "1",
  status_code: "AVAIL",
  extra_data: "",
  commodity_id: "",
  service_level_id: "1",
  weight: "",
  width: "",
  height: "",
  pieces: "",
  pallets: "",
  _length: "",
  pickup_latitude: 0,
  pickup_longitude: 0,
  delivery_latitude: 0,
  delivery_longitude: 0,
}

const buildFilters = (newValues: Partial<LoadFilters> = {}): LoadFilters => ({
  search: "",
  order_by: "",
  asc_or_desc: "",
  per_page: 0,
  commodity_name: "",
  serviceLevel_name: "",
  terminal_id: "",
  terminal_name: "",
  page: 0,
  ...newValues,
})

const filters = buildFilters({
  order_by: "bill_number",
  asc_or_desc: "desc",
  // per_page: 300,
  per_page: 30,
  page: 1,
})

const formUpdateStatus = {
  status_id: 0,
  freight_id: 0,
  comments: "",
}

const accessorialChargeForm = {
  id: 0,
  accharge_id: "",
  freight_id: "",
  quantity: "",
  price: "",
}

const traceNumberForm = {
  id: 0,
  type_trace_id: "",
  freight_id: "",
  number: "",
}

const carrierForm = {
  carrier_id: "",
  pickup: true,
  delivery: true,
  charge: "",
}

const formCopy = {
  COMMODITY: false,
  TRACES: false,
  SERVICE_LEVEL: false,
  ORIGIN: false,
  DESTINE: false,
  CUSTOMER: false,
}

const accessorialChargeCarrierForm = {
  id: 0,
  carrier_id: "",
  accharge_id: "",
  freight_id: "",
  quantity: "1",
  price: "",
}

export default {
  form,
  filters,
  formUpdateStatus,
  accessorialChargeForm,
  accessorialChargeCarrierForm,
  traceNumberForm,
  formCopy,
  carrierForm,
  COLUMNS_LOADS,
  COLUMNS_DETAILS,
  buildFilters,
}
