import { PreviewPDF } from "@/@core/PreviewPDF"
import type { Load, DocumentFile } from "../LoadType"
import { ReactNode, useState } from "react"
import { createPortal } from "react-dom"
import { PreviewImage } from "@/@core/PreviewImage"
import IconPdf from "@/assets/images/icon-pdf-01.png"

interface Props {
  files: Load["documents"]
  children?: ReactNode
}

export const PreviewFile = ({ files, children }: Props) => {
  const isPDF = (fileExtension: string): boolean => fileExtension === "pdf"
  const [showPreview, setShowPreview] = useState<string>()
  const [uri, setUri] = useState<string>()

  const closeModal = () => {
    setShowPreview(undefined)
    setUri(undefined)
  }

  const openShowPreview = (value: DocumentFile, typeFile: string) => {
    setShowPreview(typeFile)
    setUri(value.url)
  }

  return (
    <>
      {showPreview == "pdf"
        ? createPortal(<PreviewPDF url={uri!} onClose={closeModal} />, document.body)
        : showPreview == "image"
          ? createPortal(<PreviewImage url={uri!} onClose={closeModal} />, document.body)
          : null}
      <div className="grid grid-cols-3 sm:grid-cols-5 gap-1">
        {files.map((item, index) => (
          <button
            onClick={() => openShowPreview(item, isPDF(item.file_extension) ? "pdf" : "image")}
            key={index}
            className="flex flex-col items-center gap-2 border rounded dark:border-slate-700 relative"
          >
            <figure className="text-center items-center justify-center object-cover w-full h-[150px] overflow-hidden">
              {isPDF(item.file_extension) ? (
                <img src={IconPdf} className="mx-auto h-[100px] object-cover mt-2" alt="Icon" />
              ) : (
                <img src={item.url} className="object-cover w-full h-[150px] hover:scale-110 transition-all" alt="Icon" />
              )}
            </figure>
            <div className="text-center absolute bottom-0 left-0 right-0 bg-slate-800 pb-1 pt-2">
              <p className="leading-none block text-xs text-gray-300 dark:text-white mb-1">{item.type_documents.replace(/_/g, " ")}</p>
              <p className="leading-none text-xs uppercase text-slate-300 dark:text-slate-400 font-semibold">{item.file_extension}</p>
            </div>
          </button>
        ))}
        {children}
      </div>
    </>
  )
}
