import { AxiosError, AxiosResponse } from "axios"
import Swal, { SweetAlertOptions } from "sweetalert2"

type TParams = {
  text?: string
  trans(prop: any): any
  writeOriginText?: boolean
}

type TPayload = {
  text?: string
  $t(prop: any): any
  showOriginalText?: boolean
  preConfirm?: SweetAlertOptions["preConfirm"]
  promise?: () => Promise<AxiosResponse<ResponseDelete>>
}

const textConfirmDestroyDefault = "You won't be able to revert this!"
const textConfirmUnsignedDefault = "Are you want to unassign?"

export class SwalConfig {
  static questionDestroy({ text = textConfirmDestroyDefault, trans }: TParams = { trans: () => {} }): SweetAlertOptions {
    return {
      title: trans({ id: "are you sure?" }),
      text: trans({ id: text }),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ef4444",
      cancelButtonColor: "#6c757d",
      confirmButtonText: trans({ id: "yes, delete it!" }),
      cancelButtonText: trans({ id: "Cancel" }),
    }
  }
  static questionConfirm(
    { text = "are you sure you want to continue with this action?", trans, writeOriginText = false }: TParams = { trans: () => {} },
  ): SweetAlertOptions {
    return {
      title: trans({ id: "are you sure?" }),
      html: writeOriginText ? text : trans({ id: text }),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#041731",
      cancelButtonColor: "#6c757d",
      confirmButtonText: trans({ id: "yes, continue" }),
      cancelButtonText: trans({ id: "Cancel" }),
    }
  }
  static questionUnassigned({ text = "Are you want to unassign?", trans, writeOriginText = false }: TParams = { trans: () => {} }): SweetAlertOptions {
    return {
      title: trans({ id: "are you sure?" }),
      text: writeOriginText ? text : trans({ id: text }),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2563EB",
      cancelButtonColor: "#6c757d",
      confirmButtonText: trans({ id: "yes, unassign!" }),
      cancelButtonText: trans({ id: "Cancel" }),
    }
  }
  static questionAssign({ text = "", trans, writeOriginText = false }: TParams = { trans: () => {} }): SweetAlertOptions {
    return {
      title: trans({ id: "are you sure?" }),
      text: writeOriginText ? text : trans({ id: text }),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2563EB",
      cancelButtonColor: "#6c757d",
      confirmButtonText: trans({ id: "yes, assign!" }),
      cancelButtonText: trans({ id: "Cancel" }),
    }
  }
  static questionChangeStatus({ text = "Are you sure you want to change status?", trans }: TParams = { trans: () => {} }): SweetAlertOptions {
    return {
      title: trans({ id: "are you sure?" }),
      text: trans({ id: text }),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2563EB",
      cancelButtonColor: "#6c757d",
      confirmButtonText: trans({ id: "yes, change!" }),
      cancelButtonText: trans({ id: "Cancel" }),
    }
  }
  static questionClean({ text = "Are you sure you want to clean?", trans }: TParams = { trans: () => {} }): SweetAlertOptions {
    return {
      title: trans({ id: "are you sure?" }),
      text: trans({ id: text }),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2563EB",
      cancelButtonColor: "#6c757d",
      confirmButtonText: trans({ id: "yes, clean!" }),
      cancelButtonText: trans({ id: "Cancel" }),
    }
  }
  static questionUpdateColumns({ trans }: TParams = { trans: () => {} }): SweetAlertOptions {
    return {
      title: trans({ id: "are you sure?" }),
      text: trans({ id: "Are you sure you want to update the columns?" }),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2563EB",
      cancelButtonColor: "#6c757d",
      confirmButtonText: trans({ id: "yes, update!" }),
      cancelButtonText: trans({ id: "Cancel" }),
    }
  }
  static questionAssignArrDocks({ trans }: TParams = { trans: () => {} }): SweetAlertOptions {
    return {
      title: trans({ id: "are you sure?" }),
      html: `
        <div>
          <p>${trans({ id: "there are unfinished pickups remaining on the trips. would you like to proceed with arriving at the dock" })}</p>
          <br />
          <p>${trans({ id: "the pickups will be unmatched from the trip" })}</p>
        </div>
      `,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2563EB",
      cancelButtonColor: "#6c757d",
      confirmButtonText: trans({ id: "yes, update!" }),
      cancelButtonText: trans({ id: "Cancel" }),
    }
  }
  // 💙 Upgrades
  static confirmDestroy({ $t, text = textConfirmDestroyDefault, showOriginalText = false, preConfirm, promise }: TPayload): SweetAlertOptions {
    if (!preConfirm && !promise) {
      console.warn("It must have at least one of the 2 parameters preConfirm, promise")
      return {}
    }

    let preConfirmHandler
    if (promise) {
      preConfirmHandler = async () => {
        try {
          Swal.showLoading()
          const { status } = await promise()
          if (status === 204) {
            return new Promise((resolve) => resolve(true))
          }
        } catch (error) {
          if (error instanceof AxiosError) {
            if (error.response?.data) {
              Swal.fire({ icon: "error", title: "Error", text: error.response.data?.msg || "Error" })
              throw error.response?.data
            }
          }
        }
      }
    } else {
      preConfirmHandler = preConfirm
    }

    return {
      title: $t({ id: "are you sure?" }),
      text: showOriginalText ? text : $t({ id: text }),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#ef4444",
      cancelButtonColor: "#6c757d",
      confirmButtonText: $t({ id: "yes, delete it!" }),
      cancelButtonText: $t({ id: "Cancel" }),
      allowOutsideClick: false,
      allowEscapeKey: false,
      preConfirm: preConfirmHandler,
    }
  }
  static confirmUnassigned({ $t, text = textConfirmUnsignedDefault, showOriginalText = false, preConfirm, promise }: TPayload): SweetAlertOptions {
    if (!preConfirm && !promise) {
      console.warn("It must have at least one of the 2 parameters preConfirm, promise")
      return {}
    }

    let preConfirmHandler
    if (promise) {
      preConfirmHandler = async () => {
        try {
          Swal.showLoading()
          const { status } = await promise()
          if (status === 204) {
            return new Promise((resolve) => resolve(true))
          }
        } catch (error) {
          if (error instanceof AxiosError) {
            if (error.response?.data) {
              Swal.fire({ icon: "error", title: "Error", text: error.response.data?.msg || "Error" })
              throw error.response?.data
            }
          }
        }
      }
    } else {
      preConfirmHandler = preConfirm
    }

    return {
      title: $t({ id: "are you sure?" }),
      text: showOriginalText ? text : $t({ id: text }),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2563EB",
      cancelButtonColor: "#6c757d",
      confirmButtonText: $t({ id: "yes, unassign!" }),
      cancelButtonText: $t({ id: "Cancel" }),
      allowOutsideClick: false,
      allowEscapeKey: false,
      preConfirm: preConfirmHandler,
    }
  }
}
