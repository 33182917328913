import { TbChevronDown, TbX } from "react-icons/tb"
import clsx from "clsx"
import React, { createContext, useContext, useRef, useState } from "react"
import { Input } from "./Input"
import useClickOutside from "@/hooks/useClickOutside"

interface Props<K> {
  className?: string
  children: (item: K, index?: number) => React.ReactNode
  options: K[]
  onSelected: (item: K) => void
  onDeselected?: () => void
  value?: string
  id?: string
  name?: string
  onChange?: (ev: React.ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
}

interface OptionProps<K> {
  className?: string
  children: React.ReactNode
  data: K
}

interface TSelectSearchContext {
  open: boolean
  toggle: React.Dispatch<React.SetStateAction<boolean>>
  handleSelectedClick: (value: any) => void
}

const SelectSearchContext = createContext<TSelectSearchContext>({} as TSelectSearchContext)

export function SelectAsync<K>({ children, className, options, onSelected, onDeselected, placeholder = "Search", ...props }: Props<K>) {
  const [open, toggle] = useState<boolean>(false)
  const inputRef = useRef<HTMLDivElement | null>(null)

  useClickOutside(inputRef, () => {
    if (open) toggle(false)
  })

  const renderOption = (item: K, index: number) => children(item, index)

  const handleSelectedClick = (item: K) => {
    setTimeout(() => {
      onSelected(item)
      toggle(false)
    }, 100)
  }

  const resetState = () => {
    toggle(false)
    if (onDeselected) {
      onDeselected()
    }
  }

  const onFocus = () => toggle(true)

  return (
    <div className="relative z-10" ref={inputRef}>
      <SelectSearchContext.Provider value={{ open, toggle, handleSelectedClick }}>
        <div className="relative group/item">
          <Input
            className={clsx(
              "block search appearance-none w-full bg-white border border-gray-300 text-sm  focus:border-blue-400 ps-3 py-1 !pe-8 rounded sm:leading-6 focus:outline-none placeholder:opacity-80 focus:placeholder:opacity-40",
              className,
            )}
            onFocus={onFocus}
            placeholder={placeholder}
            autoComplete="off"
            onBlur={() => setTimeout(() => toggle(false), 100)}
            maxLength={255}
            {...props}
          />
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-0.5 text-gray-400">
            <button
              type="button"
              className={clsx(
                "invisible text-sm relative pointer-events-auto select-none text-red-600 cursor-pointer",
                !!props.value?.length && "group-hover/item:visible",
              )}
              onClick={(ev) => {
                ev.preventDefault()
                ev.stopPropagation()
                resetState()
              }}
            >
              <TbX />
            </button>
            <TbChevronDown />
          </div>
        </div>
        {open && !!options.length && (
          <div className="w-full flex flex-col border dark:border-slate-700 rounded overflow-y-auto overflow-x-hidden max-h-[250px] scroll__styled absolute top-full">
            {options.map((item, index) => renderOption(item, index))}
          </div>
        )}
      </SelectSearchContext.Provider>
    </div>
  )
}

export function Option<K>({ data, children, className, ...props }: OptionProps<K>) {
  const { handleSelectedClick } = useContext(SelectSearchContext)
  return (
    <button
      role="button"
      type="button"
      className={clsx(
        "flex-1 w-full bg-white hover:bg-gray-100 p-1 text-xs text-start dark:bg-slate-800 dark:text-slate-300 dark:hover:bg-slate-700 dark:ring-gray-700",
        className,
      )}
      onClick={() => handleSelectedClick(data)}
      {...props}
    >
      {children}
    </button>
  )
}

SelectAsync.Option = Option
